/** 
 * MATERIAL THEME
 */
@use "@angular/material" as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$custom-typography: mat.define-legacy-typography-config(
  $font-family: "Poppins",
);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$blue-palette),
      accent: mat.define-palette(mat.$amber-palette),
    ),
  )
);

$dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: mat.define-palette(mat.$grey-palette),
      accent: mat.define-palette(mat.$yellow-palette),
    ),
  )
);

@include mat.all-legacy-component-themes($light-theme);
// @include mat.all-component-typographies();
@include mat.legacy-typography-hierarchy($custom-typography);

/* You can add global styles to this file, and also import other style files */
body {
  --bg: white;
  --text-primary: black;
  --text-secondary: #666;
  --label-large-color: rgb(33, 95, 171);
  --accent-skip: green;
  --accent-cancel: rgb(191, 33, 33);
  --top-strip-bg: #074280;
  --top-strip-btn-bg: #c5c3c3;
  --top-strip-btn-text: white;
  --theme-switch-border: black;
  --nav-btn-hover-bg: rgb(74, 195, 169);

  //login page
  --login-card-bg: #fff9f9;
  --login-header-color: #074280;
  // --login-header-color: #51097d;
  --login-underline-color: #e5a442;
  --fgp-text-color: red;

  --swal-btn-color: #2179d7;
  --swal-btn-text-color: white;
  --swal-bg: white;
  --swal-text-color: black;
  --terms-conditions-color: #353535;
}

body.high-contrast {
  @include mat.all-legacy-component-colors($dark-theme);

  --bg: black;
  --text-primary: white;
  --text-secondary: #eee;
  --accent: white;
  // --accent-skip: rgb(130, 255, 130);
  --accent-skip: yellow;
  // --accent-cancel: rgb(255, 112, 112);
  --accent-cancel: yellow;
  --label-large-color: rgb(0, 253, 255);
  --header-border: white;
  --top-strip-bg: rgb(0, 0, 0);
  --top-strip-btn-text: white;
  --top-strip-border: white;
  --top-strip-btn-bg: rgb(230, 230, 230);
  --theme-switch-border: yellow;
  --login-subtitle-color: #6d6666;
  --terms-conditions-color: white;
  --nav-btn-hover-bg: yellow;

  //login page
  --login-page-bg: black;
  --login-card-bg: black;
  --login-card-border: white;
  // --login-header-color: yellow;
  --login-header-color: white;
  --login-underline-color: white;
  --login-subtitle-color: #eee;
  --fgp-text-color: yellow;

  --swal-btn-color: yellow;
  --swal-btn-text-color: black;
  --swal-bg: black;
  --swal-border: white;
  --swal-text-color: white;

  svg {
    path {
      stroke: white !important;
    }
  }

  .bg {
    display: none !important;
  }
}

html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  background: var(--bg);
}
.mat-form-field-prefix {
  padding-right: 5px !important;
  white-space: nowrap;
  flex: none;
  position: relative;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  font-size: 1.7rem !important;
  font-family: Poppins, Roboto, "Helvetica Neue", sans-serif !important;
  background-color: var(--swal-bg) !important;
  border: 1px solid var(--swal-border) !important;
  color: var(--swal-text-color) !important;

  ol {
    text-align: left !important;
    margin-left: 10%;
  }
}

.swal2-confirm {
  background-color: var(--swal-btn-color) !important;
  color: var(--swal-btn-text-color) !important;
}

body {
  background: linear-gradient(
    90deg,
    rgb(55, 109, 218) 0%,
    rgb(36, 115, 252) 47%,
    rgba(15, 206, 222, 1) 96%
  );
}

body.high-contrast {
  background: black !important;
}
